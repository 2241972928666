
import React, { useState } from 'react';
import ChatWindow from './ChatWindow';
import ChatIcon from './ChatIcon';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';


function Chatbot() {
    const showChat = useSelector((state) => state.chat.showChat);
    const [sessionId] = useState(uuidv4)
      return (
    <>
     {showChat && <ChatWindow sessionId={sessionId}/>} 
    <ChatIcon/>
    </>
  );
}

export default Chatbot;
