
import React from 'react';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toggleChat } from './store/chatSlice';


function ChatIcon() {
  const dispatch = useDispatch()
  // const showChat = useSelector((state) => state.chat.showChat);
  return (
    <div className="chat-icon-container" onClick={() => dispatch(toggleChat())}>
      <img id="chat-icon-internal" alt="Launcher icon" src="https://d2uhloicyvrx5p.cloudfront.net/messenger/launcher-icon.svg" class="bs-chatbot-fab--icon__22tcL open__39RDt lead-identification__37nG5" style={{ height: "40px !important", width: "40px !important" }} />
      <div className='dot-container'>
        <span id="dot1" class="dot"></span>
        <span id="dot2" class="dot"></span>
        <span id="dot3" class="dot"></span>
      </div>

    </div>
  );
}

export default ChatIcon;
