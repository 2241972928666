
import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './chatSlice.js';
import messageSlice from './messageSlice.js';

const store = configureStore({
  reducer: {
    chat: chatReducer,
    message: messageSlice
  },
});

export default store;
