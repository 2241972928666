
import { addmessage } from './store/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';



function ChatWindow({ sessionId }) {

  const dispatch = useDispatch()
  const endOfMessagesRef = useRef(null);
  const [input, setInput] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const messages = useSelector((state) => state.message.messages);
  
  const handleSend = () => {
    if (input && input.trim()) {
      setIsTyping(true);
      dispatch(addmessage({ sender: 'user', text: input }))
      fetch('https://vercelchatbot-dusky.vercel.app/api/sendMessage', {
      // fetch('http://localhost:5000/api/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: input, sessionId: sessionId })
      })
        .then(response => {

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          else {
            return response.json();
          }

        }).then(data => {
          dispatch(addmessage({ sender: 'bot', text: data.reply }));
          setIsTyping(false)
        })
        .catch(error => {
          console.log('There was a problem with the fetch operation:', error.message);
          setIsTyping(false);
        });
      setInput('');
    }
  }

  const scrollToBottom = () => {
    endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="chat-window">
        <div className="chat-messages">
          {messages.map((message, index) => (

            message.text.trim().length > 1 ? <div
              key={index}
              className={message.sender === 'user' ? 'user-message' : 'bot-message'}
            >
              {message.text}
            </div> : null

          ))}
          <div ref={endOfMessagesRef}></div>
        </div>

        <div className="chat-input">
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="Type a message..."
          />
          <button onClick={handleSend}>Send</button>
        </div>
        <div className='typing'>
          {isTyping && <p>muSharp is typing...</p>}
        </div>
      </div>
    </>

  );
}

export default ChatWindow;
