
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showChat: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleChat: (state) => {
      state.showChat = !state.showChat;
    }
  },
});

export const { toggleChat, openChat, closeChat } = chatSlice.actions;
export default chatSlice.reducer;
