
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [{ sender: 'bot', text: 'Hello, How may I help you?' }],
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    addmessage: (state,action) => {
      state.messages.push(action.payload)
    },
    resetmessage: (state,action) =>{              // Not used yet. In future can be used to clear chat 
      state.messages=initialState.messages;
    }
  },
});

export const { addmessage, resetmessage } = messageSlice.actions;
export default messageSlice.reducer;
